@import "~normalize.css/normalize.css";

// BluePrint JS
@import "~@blueprintjs/core/lib/scss/variables";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";

@import "./assets/styles/variables.scss";


.csd-yellow {
  color: $csd-yellow;
}

.csd-gray {
  color: $csd-gray;
}

.csd-blue {
  color: $csd-blue;
}

.csd-orange {
  color: $csd-orange;
}

.csd-ping {
  color: $csd-pink;
}

.csd-purple {
  color: $csd-purple;
}


.info-box {
  $csd-flow-background: #f0fafa;
  background-color: $csd-flow-background;
  box-shadow: inset 0 0 2px darken($csd-flow-background, 10%);
  padding: 1em;
}


.csd-flow {
  @extend .info-box;
  background-image: url("./assets/images/csd-flow-blue.svg");  
  background-repeat: no-repeat;  
  background-position: right -540px bottom -600px;
  background-clip: border-box;
}


.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
}

.hcentered {
  width: 100%;  
  display: flex;
  justify-content: center;

  // & > * {
  //   flex: 1;
  // }
}

.vcentered {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
}

.centered {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.intent-primary {
  //@include intent(danger);
  color: $pt-intent-primary;
}

.intent-success {
  //@include intent(success);
  color: $pt-intent-success;
}

.intent-warning {
  //@include intent(success);
  color: $pt-intent-warning;
}

.intent-danger {
  //@include intent(danger);
  color: $pt-intent-danger;
}


.files {

  .instructions {
    code {
      box-shadow: unset;
      background: rgba(#fff, 0.6)
    }

    & > div {
      margin: 20px 0;
    }
  }

  .file {
    margin: 60px 0;
    padding: 1em 0 1em 1em;
    display: flex;
    border-radius: 5px;  
    box-shadow: 
      inset 0 0 1px $gray1, 
      0px 0px 2px $light-gray1;
  
    .download {
      height: auto;
      width: 110px;
      border-left: solid 1px $light-gray1;
      flex-direction: column;    
    }
  }
}



// Give buttons inside BP dialogs actions area a min-width
.#{$ns}-dialog-footer-actions {
  button.#{$ns}-button {
      min-width: 80px;
      margin-left: 10px;
      margin-right: 10px;
  }
}


// .bordered {
//   border: solid 1px $pt-divider-black;
// }

.elevated {
  box-shadow: $pt-elevation-shadow-2;
}